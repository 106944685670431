<div class="container mx-auto px-4 py-8">
  <h1 class="text-4xl font-bold text-primary mb-6">Join Our Mailing List</h1>
  
  <div class="bg-white shadow-lg rounded-lg p-8 mb-12">
    <h2 class="text-2xl font-bold text-primary mb-4">Stay Connected</h2>
    <p class="text-lg text-gray-700 mb-6">
      Join our mailing list to receive updates about new organizations, upcoming events, and ways to get involved in building a better world for all of us.
    </p>

    <!-- MailerLite Universal -->
    <script>
      (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
      .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
      n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
      (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
      ml('account', '1225308');
    </script>
    <!-- End MailerLite Universal -->

    <div class="ml-embedded" data-form="4hyUjU"></div>
  </div>
</div>
<script lang="ts">
  import { Link } from "svelte-routing";
</script>


<div class="container mx-auto px-4 py-8">
  <h1 class="text-4xl font-bold text-primary mb-6">Conveners</h1>

  <div class="space-y-8">
    <div>
      <h2 class="text-2xl font-semibold">Alexandria Shaner</h2>
      <p class="text-lg mt-2">
        Originally from the US, Alexandria has lived most of her life in the Caribbean, as well as in Egypt and Central America. A sailor, writer, organizer, and street medic, she has been involved in community organizing, media, and education for over 20 years. Alexandria is currently a staff member of ZNetwork.org and a writer for Extinction Rebellion. She is active with Caracol DSA: the Degrowth Ecosocialist Caucus and the Women's Rights and Empowerment Network.
      </p>
    </div>

    <div>
      <h2 class="text-2xl font-semibold">Evan Henshaw-Plath</h2>
      <p class="text-lg mt-2">
        Evan is an activist and technologist passionate about building commons-based social media apps that prioritize equity and sustainability. With experience in developing platforms like Twitter, he has explored innovative digital spaces through projects like Planetary on Scuttlebutt and nos.social on Nostr.
      </p>
    </div>

    <div>
      <h2 class="text-2xl font-semibold">Cynthia Peters</h2>
      <p class="text-lg mt-2">
        Cynthia Peters is a longtime Boston-based community organizer in housing justice and anti-war efforts. She is an editor and writer, whose essays have been published in ZNet, Dollars and Sense, various books, and other media outlets. She edits The Change Agent, an online social justice magazine for immigrant and other adult learners.
      </p>
    </div>

    <div>
      <h2 class="text-2xl font-semibold">Arash Kolahi</h2>
      <p class="text-lg mt-2">
        Arash is a left economist, social theorist & consultant. He has over 15 years of experience in economic & financial analysis, consulting & research. His primary areas of interest include economic theory, computer science, behavioral economics, human nature, psychology & sociology. Arash has consulted numerous enterprises on workplace culture, organizational decision-making, incentive structures, data analytics, risk management, customer experience, sustainability, diversity, equity & inclusion.
      </p>
    </div>

    <div>
      <h2 class="text-2xl font-semibold">Leslie Cagan</h2>
      <p class="text-lg mt-2">
        Leslie Cagan is an American activist, writer, and socialist organizer involved with the peace and social justice movements. She is the former national coordinator of United for Peace and Justice, the former cochair of Committees of Correspondence for Democracy and Socialism, and the former chair of Pacifica Radio.
      </p>
    </div>

    <div>
      <h2 class="text-2xl font-semibold">Michael Albert</h2>
      <p class="text-lg mt-2">
        Michael Albert's political involvements have ranged from local, regional, and national organizing projects and campaigns to co-founding South End Press, Z Magazine, the Z Media Institute, and ZNet, and to working on all these projects, writing for various publications and publishers, giving public talks, etc. Albert is the co-author of the post capitalist vision, participatory economics. Michael is currently host of the podcast RevolutionZ.
      </p>
    </div>
  </div>

  <div class="mb-8">
    <br>
    <img 
      src="/assets/chalk_mural.jpg"
      alt="People working together in mutual aid"
      class="w-full h-auto rounded-lg shadow-lg"
      loading="lazy"
    />
  </div>

</div>